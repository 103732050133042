import React, {Component} from 'react';
import {Link, Redirect, withRouter} from 'react-router-dom'
import {DOMAIN, IMAGE_ROOT} from "../api-config";


class topbar extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            first: "first",
            second: "second",
            fourth: "fourth",
            last: "last",

            subfirst: "active",
            subsecond: "",
            subfourth: "",
            sublast: "",
            user_id: "",
            redirectLocation: "",
            red: false
        };

    };

    logout = e => {
        sessionStorage.clear();
        localStorage.removeItem("user_id");
        localStorage.removeItem("user_email");
        localStorage.removeItem("user_name");
        localStorage.removeItem("waivers");

        if (this.props.location.pathname.indexOf("/events") > -1) {

            //  this.setState({red:true});
        }
        this.setState({redirect: true});
    }

    componentDidMount() {
        var user_id = localStorage.getItem('user_id');
        this.setState({user_id: user_id});

        console.log("PATH: " + this.props.location.pathname);

        if (this.props.location.pathname.indexOf("/login") > -1) {

            this.setState({first: "first"});
            this.setState({second: "second"});
            this.setState({fourth: "fourth active"});
            this.setState({last: "last"});

            this.setState({subfirst: ""});
            this.setState({subsecond: ""});
            this.setState({subfourth: "active"});
            this.setState({sublast: ""});
        }else if (this.props.location.pathname.indexOf("/forgotpassword") > -1) {

            this.setState({first: "first"});
            this.setState({second: "second"});
            this.setState({fourth: "fourth active"});
            this.setState({last: "last"});

            this.setState({subfirst: ""});
            this.setState({subsecond: ""});
            this.setState({subfourth: "active"});
            this.setState({sublast: ""});
        } else if (this.props.location.pathname.indexOf("/signup") > -1) {

            this.setState({first: "first"});
            this.setState({second: "second"});
            this.setState({fourth: "fourth"});
            this.setState({last: "last active"});

            this.setState({subfirst: ""});
            this.setState({subsecond: ""});
            this.setState({subfourth: ""});
            this.setState({sublast: "active"});
        } else if (this.props.location.pathname.indexOf("/admission") > -1) {

            this.setState({first: "first"});
            this.setState({second: "second active"});
            this.setState({fourth: "fourth"});
            this.setState({last: "last"});


            this.setState({subfirst: ""});
            this.setState({subsecond: "active"});
            this.setState({subfourth: ""});
            this.setState({sublast: ""});
        } else if (this.props.location.pathname.indexOf("/myaccount") > -1) {

            this.setState({first: "first"});
            this.setState({second: "second"});
            this.setState({fourth: "fourth active"});
            this.setState({last: "last"});


            this.setState({subfirst: ""});
            this.setState({subsecond: ""});
            this.setState({subfourth: "active"});
            this.setState({sublast: ""});
        } else if (this.props.location.pathname.indexOf("/receipt") > -1) {

            this.setState({first: "first"});
            this.setState({second: "second"});
            this.setState({fourth: "fourth"});
            this.setState({last: "last"});


            this.setState({subfirst: ""});
            this.setState({subsecond: ""});
            this.setState({subfourth: ""});
            this.setState({sublast: ""});
        } else if (this.props.location.pathname.indexOf("/event") > -1) {

            if (sessionStorage.getItem("eventType") == "E") {
                this.setState({first: "first active"});
                this.setState({second: "second"});
                this.setState({fourth: "fourth"});
                this.setState({last: "last"});
            } else {
                this.setState({first: "first"});
                this.setState({second: "second active"});
                this.setState({fourth: "fourth"});
                this.setState({last: "last"});

            }


        } else {
            this.setState({first: "first active"});
            this.setState({second: "second"});
            this.setState({fourth: "fourth"});
            this.setState({last: "last"});


            this.setState({subfirst: "active"});
            this.setState({subsecond: ""});
            this.setState({subfourth: ""});
            this.setState({sublast: ""});
        }
    }

    render() {
        if (this.state.red) {
            return <Redirect to={"/" + this.state.redirectLocation}/>;
        }
        return (<div id="header-wrapper" className="bg-white">
            <header>
                <div id="header" className="container">
                    <div id="menu">
                        <ul>
                            <li className={this.state.first}><Link to="/party" accessKey="1" title="">Book Party</Link>
                            </li>
                            <li className={this.state.second}><Link to="/admission" accessKey="2" title="">Buy
                                Admission</Link>
                            </li>
                            <li className="logo"><Link to="/" accessKey="3" title=""><img
                                src={IMAGE_ROOT + "/images/product/" + localStorage.getItem("merchant_image")}
                                alt="Logo"/></Link></li>

                            {(!localStorage.getItem('user_id')) ?
                                <li className={this.state.fourth}><Link to="/login" accessKey="4" title="">Login</Link>
                                </li> : <li className={this.state.fourth}><Link to="/myaccount" accessKey="5"
                                                                                title="">My Account</Link></li>
                            }
                            {(!localStorage.getItem('user_id')) ?
                                <li className={this.state.last}><Link to="/signup" accessKey="5" title="">Create
                                    Account</Link></li> : ''}
                            {/* {(localStorage.getItem('user_id')) ?
                                <li className="fourth"></li> : ''}*/}
                            {(localStorage.getItem('user_id')) ?
                                <li className={this.state.last}><Link onClick={this.logout} accessKey="5"
                                                                      title="">Logout</Link></li> : ''}
                        </ul>
                    </div>
                </div>
                <nav className="navbar navbar-expand-lg navbar-light mb-0 p-0 text-center">
                    <Link className="navbar-brand m-auto pt-0" to="/"><img style={{height: "51px"}}
                                                                           src={IMAGE_ROOT + "/images/product/" + localStorage.getItem("merchant_image")}
                                                                           alt={localStorage.getItem("merchant_name")}/></Link>
                    <button className="navbar-toggler float-right p-0 border-0 mr-3 mt-3" type="button"
                            data-toggle="collapse"
                            data-target="#navbarsExample09" aria-controls="navbarsExample09"
                            aria-expanded="false"
                            aria-label="Toggle navigation">
                        <img src="/img/menu-icon.png" width="24" height="21" alt="Menu"/>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarsExample09">
                        <ul className="navbar-nav mr-auto">
                            <li className={"nav-item text-left " + this.state.subfirst}>
                                <Link className="nav-link text-uppercase" to="/party">Book Party</Link>
                            </li>
                            <li className={"nav-item text-left " + this.state.subsecond}>
                                <Link className="nav-link text-uppercase" to="/admission">Buy Admission</Link>
                            </li>

                            {(!localStorage.getItem('user_id')) ?
                                <li className={"nav-item text-left " + this.state.subfourth}>
                                    <Link className="nav-link text-uppercase" to="/login">Login</Link>
                                </li> : ''}
                            {(!localStorage.getItem('user_id')) ?
                                <li className={"nav-item text-left " + this.state.sublast}>
                                    <Link className="nav-link text-uppercase" to="/signup">Create
                                        Account</Link>
                                </li> : ''}


                            {(localStorage.getItem('user_id')) ?
                                <li className={"nav-item text-left " + this.state.subfourth}>
                                    <Link className="nav-link text-uppercase" to="/myaccount">My Account</Link>
                                </li> : ''}
                            {(localStorage.getItem('user_id')) ?

                                <li className="nav-item text-left">
                                    <Link className="nav-link text-uppercase" onClick={this.logout}>Logout</Link>
                                </li> : ''}
                            <li className="nav-item arrow-div">
                                <button className="navbar-toggler bg-blue text-white border-0" type="button"
                                        data-toggle="collapse"
                                        data-target="#navbarsExample09" aria-controls="navbarsExample09"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation">
                                    <span className="icon-angle-up"></span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        </div>);
    }
}

export default withRouter(topbar);