import React, {Component} from 'react';
import {DOMAIN} from "../api-config";
import {Link, withRouter} from 'react-router-dom'

class steps extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            steps: [],

            user_id: "",
        };

    };

    moveComponent = index => {

        console.log(index);



        var curname = this.state.steps[index];
        console.log(curname);

        //sessionStorage.setItem("currentComponentName", curname);
        this.props.componentChange(curname, "");

    }

    logout = e => {
        sessionStorage.clear();
    }

    componentWillMount() {
        this.setState({steps: sessionStorage.getItem("progress").split(",")});
    }

    componentDidMount() {
        var user_id = localStorage.getItem('user_id');
        this.setState({user_id: user_id});


        var curname = this.state.steps[sessionStorage.getItem("currentComponent")];
        console.log(curname);
        sessionStorage.setItem("currentComponentName", curname);


    }

    render() {

        return (
            <div className="steps clearfix sd-display">
                <ul role="tablist">
                    {this.state.steps.map((item, index) => {

                        var curr = parseInt(sessionStorage.getItem("currentComponent"));
                        var maxC= parseInt(sessionStorage.getItem("maxTraverseComponent"));
                        var pos = "first ";

                        if (index > 0) {

                            pos = "second ";
                        }
                        if (index == this.state.steps.length - 1) {
                            pos = "last ";
                        }
                        var value = (index + 1) + ".";
                        console.log(index + " " + curr);
                        if (index == curr) {


                            pos = pos + " current";
                        } else if (index > curr) {
                            pos = pos + " disabled";
                        } else if (index < curr) {
                            pos = pos + " green";
                            value = <img className="mt-2" src="/img/check-icon-white.png" width="18"
                                         height="14" alt="Check"/>;
                        }
                        var name = "";

                        if (item == "guest") {

                            name = <span>Guest <br/>Count</span>;
                        } else if (item == "date") {
                            name = <span>Select <br/>Date</span>;
                        } else if (item == "time") {
                            name = <span>Select <br/>Time</span>;
                        } else if (item == "addons") {
                            name = <span>Select <br/>Addons</span>;
                        } else if (item == "info") {
                            name = <span>Party <br/>Info</span>;
                        } else if (item == "login") {
                            name = <span>Create <br/>Account</span>;
                        } else if (item == "waiver") {
                            name = <span>Sign <br/>Waiver</span>;
                        } else if (item == "details") {
                            name = <span>Confirm <br/>Details</span>;
                        } else if (item == "payment") {
                            name = <span>Submit <br/>Payment</span>;
                        }

                        if (index > maxC) {
                            return (
                                <li key={index} role="tab" className={pos} aria-disabled="false" aria-selected="true">
                                    <a id="steps-uid-0-t-0">
                                        <span className="number">{value}</span> {name}</a>
                                </li>);
                        } else {
                            return (
                                <li key={index} role="tab" className={pos} aria-disabled="false" aria-selected="true">
                                    {/*<Link onClick={() => this.moveComponent(index)} id="steps-uid-0-t-0">
                                        <span className="number">{value}</span> {name}</Link>*/}
                                    <a id="steps-uid-0-t-0 pe-none">
                                        <span className="number">{value}</span> {name}</a>
                                </li>);
                        }
                    })}

                </ul>

            </div>
        );
    }
}


export default withRouter(steps);