import React, {Component} from 'react';
import {DOMAIN} from "../api-config";
import {withRouter} from "react-router-dom";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Steps from './steps';
import SubSteps from './substeps';
import Swal from "sweetalert2";
import $ from "jquery";


class eventinfo extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            questions: [],
            swalButton: "",
            msg: "",
            disableBtn:true

        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });

        if (sessionStorage.getItem("userQuestions")) {
            this.setState({questions: JSON.parse(sessionStorage.getItem("userQuestions"))});
        } else {
            this.setState({questions: JSON.parse(sessionStorage.getItem("eventQuestions"))});
        }


    }

    onDropDownChange = e => {

        for (var i = 0; i < this.state.questions[$(e.target).data('position')].question_options.length; i++) {

            if (this.state.questions[$(e.target).data('position')].question_options[i].option_name === $(e.target).val()) {
                this.state.questions[$(e.target).data('position')].question_options[i].option_selected = "Y";
            } else {
                this.state.questions[$(e.target).data('position')].question_options[i].option_selected = "N";
            }
        }
        this.setState({questions: this.state.questions},function (){

            this.verifyContinueButton();

        });

    }
    onCheckboxChange = e => {

        console.log($(e.target).data('position') + " " + $(e.target).data('option'));

        if (this.state.questions[$(e.target).data('position')].question_options[$(e.target).data('option')].option_selected == "Y") {
            this.state.questions[$(e.target).data('position')].question_options[$(e.target).data('option')].option_selected = "N";
        } else {
            this.state.questions[$(e.target).data('position')].question_options[$(e.target).data('option')].option_selected = "Y";
        }


        this.setState({questions: this.state.questions},function (){

            this.verifyContinueButton();

        });



    }

    onChangeTextbox = e => {

        this.state.questions[$(e.target).data('position')].question_answer = $(e.target).val();
        this.setState({questions: this.state.questions},function (){

            this.verifyContinueButton();

        });



    }

    verifyContinueButton=()=>{
        if(this.verify()==""){

            this.setState({disableBtn:false});
        }else{
            this.setState({disableBtn:true});
        }
    }
    componentDidMount() {

        $(".custom-select").each(function () {
            $(this).wrap("<span class='select-wrapper'></span>");
            $(this).after("<span class='holder'></span>");
        });
        $(".custom-select").change(function () {
            var selectedOption = $(this).find(":selected").text();
            $(this).next(".holder").text(selectedOption);
        }).trigger('change');


        this.verifyContinueButton();

    }


    onSelectTime = e => {

        // this.setState({eventSelectedTime: this.state.timeSlots[$(e.target).data('position')]});
    }

    verify = () => {
        var hit = true;
        var msg = "";

        for (var i = 0; i < this.state.questions.length; i++) {

            if (this.state.questions[i].question_type === "T" && this.state.questions[i].question_required === "Y" && this.state.questions[i].question_answer === "") {

                msg = 'Please answer - "' + this.state.questions[i].question_name + '"';
                hit = false;
                break;
            } else if (this.state.questions[i].question_type === "R" && this.state.questions[i].question_required === "Y") {


                var flag = false;
                for (var j = 0; j < this.state.questions[i].question_options.length; j++) {
                    if (this.state.questions[i].question_options[j].option_selected === "Y") {
                        flag = true;
                        break;
                    }
                }

                if (!flag) {

                    msg = 'Please select - "' + this.state.questions[i].question_name + '"';
                    hit = false;


                    break;
                }
            } else if (this.state.questions[i].question_type === "C" && this.state.questions[i].question_required === "Y") {


                var flag = false;
                var count = 0;
                for (var j = 0; j < this.state.questions[i].question_options.length; j++) {
                    if (this.state.questions[i].question_options[j].option_selected === "Y") {
                        flag = true;
                        count++;

                    }
                }

                if (!flag) {

                    msg = 'Please select - "' + this.state.questions[i].question_name + '"';
                    hit = false;

                    break;

                } else {
                    if (parseInt(this.state.questions[i].question_max_selection) > 0) {

                        if (count > parseInt(this.state.questions[i].question_max_selection)) {
                            msg = 'You can only select ' + this.state.questions[i].question_max_selection + ' options in "' + this.state.questions[i].question_name + '"';
                            hit = false;
                            break;
                        }

                    }

                }


            }

        }




        return msg;
    }
    moveForward = () => {

        var msg =this.verify();
        if (msg=="") {
            sessionStorage.setItem("userQuestions", JSON.stringify(this.state.questions));
            this.props.onComponentUp(2);
        } else {

            this.state.swalButton.fire({
                title: msg,
                allowOutsideClick: false
            });
        }

    }
    goBack = () => {
        this.props.onComponentDown(2);
    }


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {

        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div className="title_div mb-30">
                <h1>Tell Us About Your Party ?</h1>
            </div>
            <div className="addons_block_main w-94 position-relative">
                <div className="guest_block addons_block mw95 clearfix w-80" id="style-1">
                    <form>
                        {this.state.questions.map((item, index) => {

                            var required = "";
                            if (item.question_required == "Y") {
                                required = "*";
                            }
                            if (item.question_type === "T") {

                                return (
                                    <div className="form-group">
                                        <div className="col-md-12 p-0">
                                            <label>{item.question_name}{required}</label>
                                            <input  type="text" onChange={this.onChangeTextbox}
                                                   className="form-control" value={item.question_answer}
                                                   data-position={index}/>
                                        </div>
                                    </div>)

                            } else if (item.question_type === "C") {

                                return (<div className="form-group">
                                    <div className="col-md-12 p-0">
                                        <label>{item.question_name}{required}</label>

                                        {item.question_options.map((subitem, subindex) => {

                                            var checked = false;
                                            console.log(subitem.option_selected);
                                            if (subitem.option_selected == "Y") {
                                                checked = true;
                                            }

                                            console.log("A " + checked);

                                            return (<div className="radiobtn">
                                                <input type="checkbox"
                                                       id={index + "" + subindex}
                                                       data-position={index}
                                                       data-option={subindex}
                                                       checked={checked}
                                                       onChange={this.onCheckboxChange}
                                                       value={subitem.option_name}/>
                                                <label htmlFor={index + "" + subindex}
                                                       className="mt-0">{subitem.option_name}</label>
                                            </div>)
                                        })}


                                    </div>
                                </div>)
                            } else if (item.question_type === "R") {

                                return (<div className="form-group">
                                    <div className="col-md-12 p-0">
                                        <label>{item.question_name}{required}</label>
                                        <select data-position={index} onChange={this.onDropDownChange}
                                                className="form-control custom-select">
                                            <option value="">Select Any</option>
                                            {item.question_options.map((subitem, subindex) => {

                                                var checked = false;
                                                if (subitem.option_selected === "Y") {
                                                    checked = true;
                                                }


                                                return (<option value={subitem.option_name}
                                                                selected={checked}>{subitem.option_name}</option>)
                                            })}
                                        </select>


                                    </div>
                                </div>)
                            }

                        })}


                    </form>
                </div>
            </div>
            <div className="btn-green-block clearfix">
                <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>

                {(this.state.disableBtn)?<a onClick={this.moveForward}
                                 className="btn-green btn-grey font-nunito-semibold float-right">Continue </a>:<a onClick={this.moveForward}
                                  className="btn-green  font-nunito-semibold float-right">Continue </a>}


            </div>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(eventinfo);