import React, {Component} from 'react';
import {DOMAIN} from "../api-config";
import {withRouter} from 'react-router-dom'

class substeps extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            redirect: false,
            steps: [],
            subs: [],
            user_id: "",
        };

    };

    logout = e => {
        sessionStorage.clear();
    }

    componentWillMount() {
        var steps = sessionStorage.getItem("progress");


        steps = steps.split(",");


        this.setState({steps: steps});
    }


    componentDidMount() {
        var user_id = localStorage.getItem('user_id');
        this.setState({user_id: user_id});


        /*this.state.steps.push("guest");
        this.state.steps.push("date");
        this.state.steps.push("time");


        if (sessionStorage.getItem("eventAddonsEnabled") == "Y") {
            this.state.steps.push("addons");
        }
        if (sessionStorage.getItem("eventQuestions") !== "[]") {
            this.state.steps.push("info");
        }
        if (user_id) {

        } else {
            this.state.steps.push("login");
        }
        this.state.steps.push("details");
        this.state.steps.push("payment");*/

        var curr = parseInt(sessionStorage.getItem("currentComponent"));


        if (curr == 0) {
            this.setState({subs: [this.state.steps[curr], this.state.steps[curr + 1], this.state.steps[curr + 2], this.state.steps[this.state.steps.length - 1]]});

        } else if (curr == 1) {
            this.setState({subs: [this.state.steps[0], this.state.steps[curr], this.state.steps[curr + 1], this.state.steps[this.state.steps.length - 1]]});
        } else if (curr == this.state.steps.length - 1) {
            this.setState({subs: [this.state.steps[0], this.state.steps[curr - 2], this.state.steps[curr - 1], this.state.steps[this.state.steps.length - 1]]});
        } else if (curr == (this.state.steps.length - 1) - 1) {
            this.setState({subs: [this.state.steps[0], this.state.steps[curr - 1], this.state.steps[curr], this.state.steps[this.state.steps.length - 1]]});
        } else {

            this.setState({subs: [this.state.steps[0], this.state.steps[curr], this.state.steps[curr + 1], this.state.steps[this.state.steps.length - 1]]});
        }

        /* if (this.state.steps[curr] == "guest") {

             this.setState({subs: ["guest", "date", "time", "payment"]});
         } else if (this.state.steps[curr] == "payment") {
             if (user_id) {
                 this.setState({subs: ["guest", "info", "details", "payment"]});

             } else {
                 this.setState({subs: ["guest", "login", "details", "payment"]});
             }
         } else if (this.state.steps[curr] == "date") {

             this.setState({subs: ["guest", "date", "time", "payment"]});
         } else if (this.state.steps[curr] == "time") {

             this.setState({subs: ["guest", "time", "addons", "payment"]});
         } else if (this.state.steps[curr] == "addons") {

             this.setState({subs: ["guest", "addons", "info", "payment"]});
         } else if (this.state.steps[curr] == "info") {

             if (user_id) {
                 this.setState({subs: ["guest", "info", "details", "payment"]});
             } else {
                 this.setState({subs: ["guest", "info", "login", "payment"]});
             }
         } else if (this.state.steps[curr] == "login") {


             this.setState({subs: ["guest", "login", "details", "payment"]});

         } else if (this.state.steps[curr] == "details") {


             if (user_id) {
                 this.setState({subs: ["guest", "info", "details", "payment"]});
             } else {
                 this.setState({subs: ["guest", "login", "details", "payment"]});

             }

         } else if (this.state.steps[curr] == "payment") {
             if (user_id) {
                 this.setState({subs: ["guest", "info", "details", "payment"]});
             } else {
                 this.setState({subs: ["guest", "login", "details", "payment"]});

             }
         }*/

    }

    render() {

        return (
            <div className="steps clearfix sm-display">
                <ul role="tablist">
                    {this.state.subs.map((item, index) => {


                        var curr = parseInt(sessionStorage.getItem("currentComponent"));
                        console.log(curr + " " + this.state.steps[curr] + " " + this.state.steps);
                        var pos = "first ";


                        if (index > 0) {

                            pos = "second ";
                        }
                        if (index == this.state.subs.length - 1) {
                            pos = "last ";
                        }


                        var value = (this.state.steps.indexOf(item + "") + 1) + ".";

                        if (this.state.steps.indexOf(item + "") == curr) {


                            pos = pos + " current";
                        } else if (this.state.steps.indexOf(item + "") > curr) {
                            pos = pos + " disabled";
                        } else if (this.state.steps.indexOf(item + "") < curr) {
                            pos = pos + " green";
                            value = <img className="mt-2" src="/img/check-icon-white.png" width="18"
                                         height="14" alt="Check"/>;
                        }
                        var name = "";

                        if (item == "guest") {

                            name = <span>Guest <br/>Count</span>;
                        } else if (item == "date") {
                            name = <span>Select <br/>Date</span>;
                        } else if (item == "time") {
                            name = <span>Select <br/>Time</span>;
                        } else if (item == "addons") {
                            name = <span>Select <br/>Addons</span>;
                        } else if (item == "info") {
                            name = <span>Party <br/>Info</span>;
                        } else if (item == "login") {
                            name = <span>Create <br/>Account</span>;
                        } else if (item == "waiver") {
                            name = <span>Sign <br/>Waiver</span>;
                        } else if (item == "details") {
                            name = <span>Confirm <br/>Details</span>;
                        } else if (item == "payment") {
                            name = <span>Submit <br/>Payment</span>;
                        }

                        console.log(this.state.subs);
                        return (<li key={index} role="tab" className={pos} aria-disabled="false" aria-selected="true">
                            <a id="steps-uid-0-t-0">
                                <span className="number">{value}</span> {name}</a>
                        </li>)
                    })}

                </ul>

            </div>
        );
    }
}


export default withRouter(substeps);