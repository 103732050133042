import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import Modal from "react-responsive-modal";
import Swal from "sweetalert2";
import {API_ROOT} from "../api-config";
import $ from "jquery";


class footer extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            open: false
        };

    };

    componentWillMount() {
        this.setState({
            dialog: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                },
                buttonsStyling: false,
            })
        });

    }

    componentDidMount() {


    }

    fetchMerchantParameter(type) {
        this.onOpenModal();

        var url = API_ROOT + '/fetchmerchant';

        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(merchantData => {

                this.onCloseModal();
                if (merchantData.status == 1 && merchantData.merchant_data.length > 0) {

                    var heading ="";
                    var text="";

                    if(type=="P"){

                        heading="Our Privacy Policy";
                        if(merchantData.merchant_data[0].merchant_privacy_policy!="") {
                            text = merchantData.merchant_data[0].merchant_privacy_policy;
                        }else{
                            text="No Privacy Policy available."
                        }
                    }else if(type=="R"){
                        heading="Our Refund Policy";
                        if(merchantData.merchant_data[0].merchant_refund_policy!="") {
                            text = merchantData.merchant_data[0].merchant_refund_policy;
                        }else{
                            text="No Refund Policy available."
                        }
                    }else if(type=="T"){
                        heading="Our Terms of Service";
                        if(merchantData.merchant_data[0].merchant_tos!="") {
                            text = merchantData.merchant_data[0].merchant_tos;
                        }else{
                            text="No Terms of Service available."
                        }
                    }


                    this.state.dialog.fire({
                        title: '<h4 id="guidelineHeading">' + localStorage.getItem("merchant_name") + "</h4>",
                        allowOutsideClick: false,
                        showCloseButton: false,
                        showCancelButton: false,
                        showConfirmButton: false,
                        html: "<h5 id='guidelineSubtext'>"+heading+"</h5><hr/><div id='guideLine'>" + text + "</div><hr/><h4 id='guidelineHeading'>THANK YOU FOR YOUR COOPERATION</h4><br/> <button type='button' " +
                            " id='btnUnderstand' class='guidelineButton font-nunito-semibold'>I Understand </button>",

                    });

                    $('#btnUnderstand').click(() => {
                        this.state.dialog.close();
                    });

                    //localStorage.setItem('merchant_id', merchantData.merchant_data[0].merchant_id);


                } else {

                }
            });
    }

    openPrivacy = e => {
        this.fetchMerchantParameter("P");
    }
    openRefund = e => {
        this.fetchMerchantParameter("R");
    }
    openTos = e => {
        this.fetchMerchantParameter("T");
    }
    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };

    render() {
        const {open} = this.state;
        return (<footer id="footer" className="clearfix">
            <div className="footer-links">
                <ul>
                    <li><Link onClick={this.openPrivacy}>Privacy Policy</Link></li>
                    <li><Link onClick={this.openRefund}>Refund Policy</Link></li>
                    <li><Link onClick={this.openTos}>Terms of Service</Link></li>
                </ul>
            </div>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} alt="Loading..." height={'40'} src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </footer>);
    }
}

export default withRouter(footer);
