import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {DOMAIN} from "../api-config";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Steps from './steps';
import SubSteps from './substeps';

class guestcount extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            guestCount: 1,
        };

    };

    componentWillMount() {

        sessionStorage.setItem("currentComponent", 0);
    }

    componentDidMount() {



        if (sessionStorage.getItem("userGuestCount")) {

            this.setState({guestCount: parseInt(sessionStorage.getItem("userGuestCount"))})
        } else {
            this.setState({guestCount: parseInt(sessionStorage.getItem("guestDefault"))});
        }
        console.log("GUEST COUNT");
    }

    moveForward = () => {
        sessionStorage.setItem("userGuestCount", this.state.guestCount);

        this.props.onComponentUp(2);
    }
    goBack = () => {
        this.props.onComponentDown(2);
    }


    plusQty = e => {


        var qty = this.state.guestCount;
        if (qty < parseInt(sessionStorage.getItem("guestMax"))) {


            qty = qty + 1;
            console.log("PLUS" + qty);
            this.setState({guestCount: qty});
        }

    }
    minusQty = e => {

        var qty = this.state.guestCount;
        if (qty > parseInt(sessionStorage.getItem("guestMin")) && qty > 1) {
            qty = qty - 1;
            console.log("PLUS" + qty);
            this.setState({guestCount: qty});
        }
    }

    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };

    render() {

        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div className="title_div mb-30">
                <h1>How Many Guests Total ?</h1>
            </div>
            <div className="guest_block h-auto clearfix">
                <form>
                    <div className="input-group">
                        <input type="button" value="" className="button-minus" data-field="quantity"
                               onClick={this.minusQty}/>
                        <input type="number" step="1" min="1" max={sessionStorage.getItem("guestMax")}
                               value={this.state.guestCount} name="quantity" className="quantity-field"/>
                        <input type="button" value="" className="button-plus" data-field="quantity"
                               onClick={this.plusQty}/>
                    </div>
                </form>
            </div>
            {parseInt(sessionStorage.getItem("guestDefault"))>0? <p className="text-center guest_p">{sessionStorage.getItem("eventName")} Has A Guest <span className="roboto-bold"> Limit Of {sessionStorage.getItem("guestDefault")}.</span><br/>
                ${sessionStorage.getItem("eventAdditionalGuestPrice")} Charge Per Additional Guests.</p>:''}
            <div className="btn-green-block clearfix">

                <button onClick={this.moveForward} className="btn-green font-nunito-semibold float-right">Continue
                </button>
            </div>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} id="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(guestcount);