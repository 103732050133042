import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN} from "../api-config";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Calendar from 'react-calendar';
import moment from 'moment-timezone';
import Modal from "react-responsive-modal";
import Swal from "sweetalert2";
import Steps from './steps';
import SubSteps from './substeps';

class eventdate extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            calendarReady: false,
            open: false,
            disableDates: [],
            selectedDate: "",
            preSelectedDate: "",
            swalButton: "",
            dataNotYetFetched: true,
        };

    };


    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });
        if (sessionStorage.getItem("userEventDate")) {


            this.setState({selectedDate: moment(sessionStorage.getItem("userEventDate"), "YYYY-MM-DD").toDate()});
            this.setState({preSelectedDate: sessionStorage.getItem("userEventDate")});

        }
    }


    componentDidMount() {

        console.log("EVENT DATE");

        if (sessionStorage.getItem("userEventDate")) {


            this.fetchEventDateTime(moment(sessionStorage.getItem("userEventDate"), "YYYY-MM-DD").format("YYYY-MM"));

        } else {
           this.fetchEventDateTime("");
        }
    }

    disableDates = ({activeStartDate, date, view}) => {


        return this.state.disableDates.includes(moment(date).format("YYYY-MM-DD"));

    }
    onDateSelected = (value, event) => {

        this.setState({preSelectedDate: moment(value).format("YYYY-MM-DD")});
    }
    changeMonth = ({activeStartDate, value, view}) => {

        if (view == "month") {


            this.fetchEventDateTime(moment(activeStartDate).format("YYYY-MM"));


        }

    }
    moveForward = () => {

        if (this.state.preSelectedDate == "") {

            this.state.swalButton.fire({
                title: 'Please select date.',
                allowOutsideClick: false
            });
        } else {
            sessionStorage.setItem("userEventDate", this.state.preSelectedDate);
            this.props.onComponentUp(1);
        }
    }
    goBack = () => {
        this.props.onComponentDown(0);
    }
    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };
    fetchEventDateTime = (month) => {

        this.setState({dataNotYetFetched: true});
        var eventID = sessionStorage.getItem("eventID");
        var merchantID = localStorage.getItem("merchant_id");
        var guestCount = sessionStorage.getItem("userGuestCount");
        var url = API_ROOT + '/fetcheventdatetime?event_id=' + eventID + "&month=" + month + "&merchant_id=" + merchantID + "&totalTickets=" + guestCount;

        this.onOpenModal();
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(events => {


                if (events.status == 1) {

                    var eventData = events.event_data[0];

                    var disableDays = [];
                    for (var j = 0; j < eventData.blockout_date.length; j++) {


                        disableDays.push(eventData.blockout_date[j]);
                    }
                    for (var j = 0; j < eventData.blocked.length; j++) {

                        disableDays.push(eventData.blocked[j]);
                    }


                    this.setState({disableDates: disableDays});
                    this.setState({calendarReady: true});
                    this.setState({ready: true});
                    this.setState({dataNotYetFetched: false});
                    this.onCloseModal();

                } else {

                }

            });

    };



    render() {

        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div class="title_div mb-30">
                <h1>What Day Best Fits Your Party ?</h1>
            </div>

            <div style={{margin: "10px"}}>

                <Calendar
                    onClickDay={this.onDateSelected}
                    defaultValue={this.state.selectedDate}
                    showNeighboringMonth={true}
                    minDate={moment().toDate()}
                    tileDisabled={(this.state.dataNotYetFetched) ? () => false : this.disableDates}
                    onActiveStartDateChange={this.changeMonth}/>
            </div>
            <div class="btn-green-block">
                <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>
                <button onClick={this.moveForward}
                        class="btn-green font-nunito-semibold float-right">Continue
                </button>
            </div>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} alt="Loading..." height={'40'} src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(eventdate);