import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {API_ROOT, DOMAIN} from "../api-config";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Steps from './steps';
import SubSteps from './substeps';
import Swal from "sweetalert2";
import $ from "jquery";


class eventtime extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            eventSelectedTime: "",
            timeSlots: [],
            swalButton: "",
        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });
        if (sessionStorage.getItem("userEventTime")) {


            this.setState({eventSelectedTime: sessionStorage.getItem("userEventTime")});

        }
    }


    componentDidMount() {

        this.fetchSlots();

    }

    fetchSlots = () => {

        var eventID = sessionStorage.getItem("eventID");
        var merchantID = localStorage.getItem("merchant_id");
        var guestCount = sessionStorage.getItem("userGuestCount");
        var eventDate = sessionStorage.getItem("userEventDate");
        var url = API_ROOT + '/fetchslots?event_id=' + eventID + "&event_date=" + eventDate + "&merchant_id=" + merchantID + "&totalTickets=" + guestCount;

        this.onOpenModal();
        fetch(url)
            .then(response => {
                return response.json();
            })
            .then(events => {


                if (events.status == 0) {

                    this.state.swalButton.fire({
                        title: "All slots are booked. No slots available.",
                        allowOutsideClick: false
                    });
                    this.setState({eventSelectedTime: ""});


                } else if (events.status == 1) {

                    this.setState({timeSlots: events.timeSlots});

                }
                this.onCloseModal();

            });


    }
    onSelectTime = e => {
        console.log()
        this.setState({eventSelectedTime: this.state.timeSlots[$(e.target).data('position')]});
    }

    moveForward = () => {

        if (this.state.eventSelectedTime !== "") {
            sessionStorage.setItem("userEventTime", this.state.eventSelectedTime);


            this.props.onComponentUp(2);
        } else {
            this.state.swalButton.fire({
                title: 'Please select time.',
                allowOutsideClick: false
            });
        }
    }
    goBack = () => {
        this.props.onComponentDown(2);
    }


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {

        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div className="title_div mb-30">
                <h1>What Time Best Fits Your Party ?</h1>
            </div>
            <div className="time_block_main position-relative">

                <div className="time_block clearfix" id="style-1">
                    <form>

                        {this.state.timeSlots.map((item, index) => {

                            var checked = false;
                            if (this.state.eventSelectedTime == item) {
                                checked = true;
                            }


                            return (<div className="radiobtn">
                                <input type="radio" id={index}
                                       name="time" data-position={index} onChange={this.onSelectTime}
                                       checked={checked}
                                       value={item}/>
                                <label htmlFor={index} className="mt-0">{item}</label>
                            </div>)
                        })}


                    </form>
                </div>

                {/* <div className="btn-top-bottom">
                    <a href="#" className="btn-top"><i className="icon-arrow-up"></i> </a>
                    <a href="#" className="btn-down"><i className="icon-arrow-down"></i> </a>
                </div>*/}
            </div>
            <div className="btn-green-block clearfix">
                <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>
                <a onClick={this.moveForward}
                   className="btn-green font-nunito-semibold float-right">Continue </a>
            </div>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(eventtime);