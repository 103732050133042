import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import {createBrowserHistory} from "history";


import Login from './pages/login';
import Home from './pages/home';
import Root1 from './pages/root1';
import NotFound from "./pages/notfound404";
import Signup from "./pages/signup";
import Admission from "./pages/admissions";
import Myaccount from "./pages/myaccount";
import EventDetails from "./pages/eventdetails";
import Confirm from "./pages/confirm";
import ForgotPassword from "./pages/forgotpassword";
import NotFoundMerchant from "./pages/notfoundmerchant";


const history = createBrowserHistory();


ReactDOM.render(
    <Router history={history}>
        <Switch>


            <Route exact path="/404" component={NotFoundMerchant}/>
            <Route exact path="/party" component={Home}/>
            <Route exact path="/admission" component={Admission}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/signup" component={Signup}/>
            <Route exact path="/myaccount" component={Myaccount}/>
            <Route exact path="/forgotpassword" component={ForgotPassword}/>
            <Route exact path="/event/:details" component={EventDetails}/>
            <Route exact path="/receipt/:reference" component={Confirm}/>
            <Route exact path="/" component={Root1}/>
            <Route exact path="/notfound" component={NotFound}/>
            <Route exact path="*" component={NotFound}/>

        </Switch>
    </Router>

    , document.getElementById('root'));

export default history;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
