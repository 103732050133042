import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {DOMAIN} from "../api-config";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Steps from './steps';
import SubSteps from './substeps';
import Swal from "sweetalert2";

import $ from "jquery";

class eventaddons extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            addOns: [],
            userCount: sessionStorage.getItem("userGuestCount"),
            swalButton: "",

        };

    };

    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });
        if (sessionStorage.getItem("userAddons")) {


            this.setState({addOns: JSON.parse(sessionStorage.getItem("userAddons"))});

        } else {
            this.setState({addOns: JSON.parse(sessionStorage.getItem("eventProducts"))});
        }
    }


    componentDidMount() {


    }


    onSelectTime = e => {

        // this.setState({eventSelectedTime: this.state.timeSlots[$(e.target).data('position')]});
    }
    plusQty = e => {


        var addOns = this.state.addOns;

        var qty = addOns[$(e.target).data('position')].product_quantity;
        //console.log("YOYOYO");
        //console.log(addOns[$(e.target).data('position')].product_cap_enabled + " " + qty + " " + this.state.userCount);
        if(addOns[$(e.target).data('position')].product_cap_enabled == "Y" && qty == this.state.userCount) {

        } else {
            qty = qty + 1;
            addOns[$(e.target).data('position')].product_quantity = qty;
        }
        this.setState({addOns: addOns});

    }
    minusQty = e => {

        var addOns = this.state.addOns;

        var qty = addOns[$(e.target).data('position')].product_quantity;
        if (qty > 0) {
            qty = qty - 1;
            addOns[$(e.target).data('position')].product_quantity = qty;
            this.setState({addOns: addOns});
        }

    }

    moveForward = () => {


        sessionStorage.setItem("userAddons", JSON.stringify(this.state.addOns));
        this.props.onComponentUp(2);

    }
    goBack = () => {
        this.props.onComponentDown(2);
    }


    onOpenModal = () => {
        this.setState({open: true});

    };
    onCloseModal = () => {
        this.setState({open: false});
    };


    render() {

        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>

            </div>
        );

        return (<ReactPlaceholder ready={this.state.ready}
                                  customPlaceholder={rightPlaceholder}>
            <Steps componentChange={this.props.onComponentChange}/>
            <SubSteps/>
            <div className="title_div mb-30">
                <h1>Would You Like To Purchase Addons ?</h1>
            </div>

            <div className="addons_block_main w-94  position-relative">
                <div className="guest_block addons_block mw95 clearfix w-80" id="style-1">
                    <form>
                        {this.state.addOns.map((item, index) => {


                            return (<div className="addons_div_main_block clearfix">
                                <div className="addons_inner_block">
                                    <div className="addons-left float-left">
                                        <h3 className="roboto-bold font-16 mb-1">{item.product_name}</h3>
                                        <p className="roboto-light font-14">{item.product_subtext}</p>
                                    </div>
                                    <div className="addons-middle float-left">
                                        <p className="roboto-light font-22 mt-3">${item.product_price}</p>
                                    </div>
                                    <div className="addons-right float-right">
                                        <div className="input-group">
                                            <input type="button" data-position={index} onClick={this.minusQty}
                                                   className="button-minus"
                                                   data-field="quantity"/>
                                            <input type="number" step="1" value={item.product_quantity} name="quantity"
                                                   className="quantity-field"/>
                                            <input type="button" data-position={index} onClick={this.plusQty}
                                                   className={"button-plus " + ((item.product_cap_enabled == "Y") ? item.product_quantity == this.state.userCount ? "bg-color-grey" : "" : null)}
                                                   data-field="quantity"/>
                                        </div>
                                    </div>
                                </div>
                            </div>);

                        })}


                    </form>
                </div>
            </div>
            <div className="btn-green-block clearfix">
                <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>
                <a onClick={this.moveForward} className="btn-green font-nunito-semibold float-right d-display">Add To
                    Cart And Continue </a>
                <a onClick={this.moveForward}
                   className="btn-green font-nunito-semibold float-right btn-m-display">Continue </a>


            </div>
            <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                   onClose={this.onCloseModal} center>
                <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>

                <p style={{textAlign: 'center'}}>
                    <img width={'40'} height={'40'} alt="Loading..." src={'/img/loading1.gif'}/>
                </p>
            </Modal>
        </ReactPlaceholder>)

    }
}

export default withRouter(eventaddons);