import React, {Component} from 'react';
import {withRouter} from 'react-router-dom'
import {DOMAIN} from "../api-config";
import ReactPlaceholder from "react-placeholder";
import {RectShape} from "react-placeholder/lib/placeholders";
import Modal from "react-responsive-modal";
import Swal from "sweetalert2";
import Steps from './steps';
import SubSteps from './substeps';
import BModal from "react-bootstrap/Modal";
import $ from "jquery";
import moment from "moment-timezone";

class signwaiver extends Component {

    constructor(props, context) {
        super(props, context);
        console.log(DOMAIN);

        this.state = {
            ready: true,
            open: false,
            type: "A",
            swalButton: "",
            show: false,
            waivers: [],
            editFlag: false,
            editIndex: 0
        };
    };


    componentWillMount() {
        this.setState({
            swalButton: Swal.mixin({
                customClass: {
                    confirmButton: 'btn-green',
                    cancelButton: 'btn-green'
                },
                buttonsStyling: false,
            })
        });
    }

    componentDidMount() {

        console.log("EVENT SIGN WAIVER");
        document.getElementById('waiverText').innerHTML = localStorage.getItem('merchant_liability_waiver_text');
        this.refreshWaivers();
    }

    moveForward = () => {

        if (!this.state.waivers.some(item => item.waiver_type === 'A')) {

            this.state.swalButton.fire({
                title: 'Please sign the liability waiver before proceeding.',
                allowOutsideClick: false
            });

        } else {

            this.props.onComponentUp(1);
        }
    }
    goBack = () => {
        this.props.onComponentDown(0);
    }
    onOpenModal = () => {
        this.setState({open: true});
    };
    onCloseModal = () => {
        this.setState({open: false});
    };
    onOpenSignModal = (type) => {
        this.setState({show: true});
        this.setState({type: type});
    };
    onCloseSignModal = () => {
        this.setState({show: false});
    };
    handleDropDown = e => {
        var selectedOption = $(e.target).find(":selected").text();
        $(e.target).next(".holder").text(selectedOption);
    };

    /**** Waiver Functions Start ****/
    addWaiver = e => {

        var waiver_first_name = $('#waiver_first_name').val();
        var waiver_last_name = $('#waiver_last_name').val();
        var waiver_type = this.state.type;
        var waiver_pg_first_name = this.state.type === "M" ? $('#waiver_pg_first_name').val() : "";
        var waiver_pg_last_name = this.state.type === "M" ? $('#waiver_pg_last_name').val() : "";
        var waiver_email = $('#waiver_email').val();
        var waiver_confirm_email = $('#waiver_confirm_email').val();
        var day = $('#day').val();
        var month = $('#month').val();
        var year = $('#year').val();
        var waiver_esign_first_name = $('#waiver_esign_first_name').val();
        var waiver_esign_last_name = $('#waiver_esign_last_name').val();
        var waiver_conditions = $('#waiver_conditions').is(':checked');

        if (waiver_first_name === "") {
            this.state.swalButton.fire({
                title: 'Please enter first name.',
                allowOutsideClick: false
            });

            return;
        } else if (waiver_last_name === "") {
            this.state.swalButton.fire({
                title: 'Please enter last name.',
                allowOutsideClick: false
            });

            return;
        } else if (this.state.type === "M" && waiver_pg_first_name === "") {
            this.state.swalButton.fire({
                title: 'Please enter parent or guardian\'s first name.',
                allowOutsideClick: false
            });

            return;
        } else if (this.state.type === "M" && waiver_pg_last_name === "") {
            this.state.swalButton.fire({
                title: 'Please enter parent or guardian\'s last name.',
                allowOutsideClick: false
            });

            return;
        } else if (waiver_email === "") {
            this.state.swalButton.fire({
                title: 'Please enter email.',
                allowOutsideClick: false
            });

            return;
        } else if (waiver_email !== waiver_confirm_email) {

            this.state.swalButton.fire({
                title: 'Email and Confirm Email must be matched.',
                allowOutsideClick: false
            });
            return;

        } else if (!waiver_conditions) {
            this.state.swalButton.fire({
                title: 'Please agree to the conditions to continue.',
                allowOutsideClick: false
            });

            return;
        } else if (waiver_esign_first_name === "") {
            this.state.swalButton.fire({
                title: 'Please e-sign by retyping first name.',
                allowOutsideClick: false
            });

            return;
        } else if (waiver_esign_last_name === "") {
            this.state.swalButton.fire({
                title: 'Please e-sign by retyping last name.',
                allowOutsideClick: false
            });

            return;
        }

        var waiver_dob = "";

        if (moment(year + "-" + month + "-" + day, 'YYYY-MM-DD', true).isValid()) {
            waiver_dob = year + "-" + month + "-" + day;
        } else {
            this.state.swalButton.fire({
                title: 'Please enter Birthday.',
                allowOutsideClick: false
            });

            return;
        }

        let waivers = this.getWaivers();
        if (this.state.editFlag) {
            waivers[this.state.editIndex] = {
                waiver_first_name,
                waiver_last_name,
                waiver_type,
                waiver_pg_first_name,
                waiver_pg_last_name,
                waiver_email,
                waiver_dob,
                waiver_esign_first_name,
                waiver_esign_last_name
            }
            this.setState({editFlag: false});
        } else {
            waivers.push({
                waiver_first_name,
                waiver_last_name,
                waiver_type,
                waiver_pg_first_name,
                waiver_pg_last_name,
                waiver_email,
                waiver_dob,
                waiver_esign_first_name,
                waiver_esign_last_name
            });
        }
        this.setWaivers(waivers);
        this.onCloseSignModal();
    }
    getWaivers = () => {
        const waivers = localStorage.getItem('waivers');
        if (waivers) {
            return JSON.parse(waivers);
        }
        return [];
    }
    setWaivers = (signes) => {
        localStorage.setItem('waivers', JSON.stringify(signes));
        this.refreshWaivers();
    }
    refreshWaivers = () => {
        this.setState({waivers: this.getWaivers()});
    }
    editWaiver = (index) => {

        let waiver = this.getWaivers()[index];
        console.log(waiver);
        this.onOpenSignModal(waiver.waiver_type);
        this.setState({editFlag: true});
        this.setState({editIndex: index});

        this.setState({open: true});
        setTimeout(() => {
            $('#waiver_first_name').val(waiver.waiver_first_name);
            $('#waiver_last_name').val(waiver.waiver_last_name);
            if (waiver.waiver_type === "M") {
                $('#waiver_pg_first_name').val(waiver.waiver_pg_first_name);
                $('#waiver_pg_last_name').val(waiver.waiver_pg_last_name)
            }
            $('#waiver_email').val(waiver.waiver_email);
            $('#waiver_confirm_email').val(waiver.waiver_email);
            console.log(moment(waiver.waiver_dob, 'YYYY-MM-DD').format('DD'), moment(waiver.waiver_dob, 'YYYY-MM-DD').format('MM'), moment(waiver.waiver_dob, 'YYYY-MM-DD').format('YYYY'))
            $('#day').val(moment(waiver.waiver_dob, 'YYYY-MM-DD').format('DD')).change();
            $('#month').val(moment(waiver.waiver_dob, 'YYYY-MM-DD').format('MM')).change();
            $('#year').val(moment(waiver.waiver_dob, 'YYYY-MM-DD').format('YYYY')).change();
            $('#waiver_esign_first_name').val(waiver.waiver_esign_first_name);
            $('#waiver_esign_last_name').val(waiver.waiver_esign_last_name);


        }, 500);
        this.setState({open: false});
    }
    removeWaiver = () => {
        this.setState({editFlag: false});
        let waiver = this.getWaivers();
        if (this.state.editIndex >= 0 && this.state.editIndex < waiver.length) {
            waiver.splice(this.state.editIndex, 1);
            this.setWaivers(waiver);
        }
        this.onCloseSignModal();
    }

    /**** Waiver Functions End ****/

    render() {

        const {open} = this.state;
        const rightPlaceholder = (
            <div className='my-awesome-placeholder'>
                <RectShape color='whitesmoke' className="img-fluid" style={{width: "100%", height: "642px"}}/>
            </div>
        );

        return (
            <ReactPlaceholder ready={this.state.ready} customPlaceholder={rightPlaceholder}>
                <Steps componentChange={this.props.onComponentChange}/>
                <SubSteps/>
                <div className="title_div mb-30">
                    <h1>Waiver of Liability and Indemnity Agreement</h1>
                </div>

                <div className="waiverContainer">
                    <div id="waiverText"></div>
                </div>

                <div className="d-flex justify-content-between mx-4">
                    <button onClick={() => this.onOpenSignModal('A')}
                            className="btn-blue font-nunito-semibold border-0 mr-2">Add a Signed Waiver (Adult)
                    </button>
                    <button onClick={() => this.onOpenSignModal('M')}
                            className="btn-blue font-nunito-semibold border-0 ml-2">Add a Signed Waiver (Minor)
                    </button>
                </div>

                <div className={"m-4"}>
                    {
                        this.state.waivers.map((sign, index) => (
                            <h3 onClick={() => this.editWaiver(index)} key={index} style={{cursor: "pointer"}}>
                                <img src={"../img/pencil-icon.png"} alt={"Edit Icon"} className="mr-2"/>
                                {(index + 1) + ". " + sign.waiver_first_name.toUpperCase() + " " + sign.waiver_last_name.toUpperCase() + " E-SIGNATURE"}
                            </h3>
                        ))
                    }
                </div>

                <div className="btn-green-block">
                    <a onClick={this.goBack} className="go_back_link float-left">Go Back</a>
                    <button onClick={this.moveForward}
                            className={`btn-green font-nunito-semibold float-right ${this.state.waivers.some(item => item.waiver_type === 'A') ? '' : 'disabled-btn'}`}>Continue
                    </button>
                </div>

                <BModal size="lg" show={this.state.show} onHide={this.onCloseSignModal}>
                    <BModal.Body>
                        <div className="login-inner clearfix">
                            <form className="RegisterForm" name="RegisterForm" method="post" action="#"
                                  encType="multipart/form-data">
                                <div className="blue-title bg-blue border">
                                    {this.state.type === "A" ? "Adult Waiver" : "Minor Waiver"}
                                </div>
                                <div className="form-group mt-3">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>{this.state.type === "M" && "Minor's "}First Name:</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" id="waiver_first_name"
                                                   name="fname"
                                                   placeholder={"Type" + (this.state.type === "M" ? " Minor's" : "") + " First Name Here"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>{this.state.type === "M" && "Minor's "}Last Name:</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" id="waiver_last_name"
                                                   placeholder={"Type" + (this.state.type === "M" ? " Minor's" : "") + " Last Name Here"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>{this.state.type === "M" && "Minor's "}Birthday :</label>
                                        </div>
                                        <div className="col-md-8">

                                            <div className="b_month">
                                             <span className="select-wrapper">
                                            <select id="month"
                                                    className="form-control custom-select"
                                                    onChange={this.handleDropDown}>
                                                <option>Month</option>
                                                <option value="01">January</option>
                                                <option value="02">Febuary</option>
                                                <option value="03">March</option>
                                                <option value="04">April</option>
                                                <option value="05">May</option>
                                                <option value="06">June</option>
                                                <option value="07">July</option>
                                                <option value="08">August</option>
                                                <option value="09">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select><span className="holder">Month</span> </span>
                                            </div>
                                            <div className="b_day">
                                             <span className="select-wrapper">
                                            <select id="day"
                                                    className="form-control custom-select"
                                                    onChange={this.handleDropDown}>
                                                <option>Day</option>
                                                <option value="01">1</option>
                                                <option value="02">2</option>
                                                <option value="03">3</option>
                                                <option value="04">4</option>
                                                <option value="05">5</option>
                                                <option value="06">6</option>
                                                <option value="07">7</option>
                                                <option value="08">8</option>
                                                <option value="09">9</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                                <option value="24">24</option>
                                                <option value="25">25</option>
                                                <option value="26">26</option>
                                                <option value="27">27</option>
                                                <option value="28">28</option>
                                                <option value="29">29</option>
                                                <option value="30">30</option>
                                                <option value="31">31</option>
                                            </select><span className="holder">Day</span> </span>
                                            </div>
                                            <div className="b_year">
                                            <span className="select-wrapper">
                                            <select id="year"
                                                    className="form-control custom-select"
                                                    onChange={this.handleDropDown}>
                                                <option>Year</option>
                                                <option value="2018">2023</option>
                                                <option value="2018">2022</option>
                                                <option value="2018">2021</option>
                                                <option value="2018">2020</option>
                                                <option value="2018">2019</option>
                                                <option value="2018">2018</option>
                                                <option value="2017">2017</option>
                                                <option value="2016">2016</option>
                                                <option value="2015">2015</option>
                                                <option value="2014">2014</option>
                                                <option value="2013">2013</option>
                                                <option value="2012">2012</option>
                                                <option value="2011">2011</option>
                                                <option value="2010">2010</option>
                                                <option value="2009">2009</option>
                                                <option value="2008">2008</option>
                                                <option value="2007">2007</option>
                                                <option value="2006">2006</option>
                                                <option value="2005">2005</option>
                                                <option value="2004">2004</option>
                                                <option value="2003">2003</option>
                                                <option value="2002">2002</option>
                                                <option value="2001">2001</option>
                                                <option value="2000">2000</option>
                                                <option value="1999">1999</option>
                                                <option value="1998">1998</option>
                                                <option value="1997">1997</option>
                                                <option value="1996">1996</option>
                                                <option value="1995">1995</option>
                                                <option value="1994">1994</option>
                                                <option value="1993">1993</option>
                                                <option value="1992">1992</option>
                                                <option value="1991">1991</option>
                                                <option value="1990">1990</option>
                                                <option value="1989">1989</option>
                                                <option value="1988">1988</option>
                                                <option value="1987">1987</option>
                                                <option value="1986">1986</option>
                                                <option value="1985">1985</option>
                                                <option value="1984">1984</option>
                                                <option value="1983">1983</option>
                                                <option value="1982">1982</option>
                                                <option value="1981">1981</option>
                                                <option value="1980">1980</option>
                                                <option value="1979">1979</option>
                                                <option value="1978">1978</option>
                                                <option value="1977">1977</option>
                                                <option value="1976">1976</option>
                                                <option value="1975">1975</option>
                                                <option value="1974">1974</option>
                                                <option value="1973">1973</option>
                                                <option value="1972">1972</option>
                                                <option value="1971">1971</option>
                                                <option value="1970">1970</option>
                                                <option value="1969">1969</option>
                                                <option value="1968">1968</option>
                                                <option value="1967">1967</option>
                                                <option value="1966">1966</option>
                                                <option value="1965">1965</option>
                                                <option value="1964">1964</option>
                                                <option value="1963">1963</option>
                                                <option value="1962">1962</option>
                                                <option value="1961">1961</option>
                                                <option value="1960">1960</option>
                                                <option value="1959">1959</option>
                                                <option value="1958">1958</option>
                                                <option value="1957">1957</option>
                                                <option value="1956">1956</option>
                                                <option value="1955">1955</option>
                                                <option value="1954">1954</option>
                                                <option value="1953">1953</option>
                                                <option value="1952">1952</option>
                                                <option value="1951">1951</option>
                                                <option value="1950">1950</option>
                                                <option value="1949">1949</option>
                                                <option value="1948">1948</option>
                                                <option value="1947">1947</option>
                                                <option value="1946">1946</option>
                                                <option value="1945">1945</option>
                                                <option value="1944">1944</option>
                                                <option value="1943">1943</option>
                                                <option value="1942">1942</option>
                                                <option value="1941">1941</option>
                                                <option value="1940">1940</option>
                                                <option value="1939">1939</option>
                                                <option value="1938">1938</option>
                                                <option value="1937">1937</option>
                                                <option value="1936">1936</option>
                                                <option value="1935">1935</option>
                                                <option value="1934">1934</option>
                                                <option value="1933">1933</option>
                                                <option value="1932">1932</option>
                                                <option value="1931">1931</option>
                                                <option value="1930">1930</option>
                                                <option value="1929">1929</option>
                                                <option value="1928">1928</option>
                                                <option value="1927">1927</option>
                                                <option value="1926">1926</option>
                                                <option value="1925">1925</option>
                                                <option value="1924">1924</option>
                                                <option value="1923">1923</option>
                                                <option value="1922">1922</option>
                                                <option value="1921">1921</option>
                                                <option value="1920">1920</option>
                                                <option value="1919">1919</option>
                                                <option value="1918">1918</option>
                                                <option value="1917">1917</option>
                                                <option value="1916">1916</option>
                                                <option value="1915">1915</option>
                                                <option value="1914">1914</option>
                                                <option value="1913">1913</option>
                                                <option value="1912">1912</option>
                                                <option value="1911">1911</option>
                                                <option value="1910">1910</option>
                                                <option value="1909">1909</option>
                                                <option value="1908">1908</option>
                                                <option value="1907">1907</option>
                                                <option value="1906">1906</option>
                                                <option value="1905">1905</option>
                                            </select><span className="holder">Year</span> </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.type === "M" &&
                                    <>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Parent or Guardian's First Name:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input type="texr" className="form-control"
                                                           id="waiver_pg_first_name"
                                                           name="fname"
                                                           placeholder={"Type Parent or Guardian's First Name Here"}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <label>Parent or Guardian's Last Name:</label>
                                                </div>
                                                <div className="col-md-8">
                                                    <input type="text" className="form-control" id="waiver_pg_last_name"
                                                           placeholder={"Type Parent or Guardian's Last Name Here"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>{this.state.type === "M" && "Parent or Guardian's "}Email Address
                                                :</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="email" id="waiver_email" className="form-control"
                                                   placeholder={"Type" + (this.state.type === "M" ? " Parent or Guardian's" : "") + " Email Address Here"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Confirm{this.state.type === "M" && " Parent or Guardian's "} Email
                                                :</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="email" id="waiver_confirm_email"
                                                   className="form-control"
                                                   placeholder={"Type" + (this.state.type === "M" ? " Parent or Guardian's" : "") + " Email Address Again"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-1">
                                            <input type="checkbox" id="waiver_conditions" className="form-control"/>
                                        </div>
                                        <div className="col-md-11">
                                            <div className={"conditionsContainer"}>
                                                By checking this box, I hereby provide my explicit consent for the use
                                                of an electronic waiver instead of an original waiver on paper for
                                                all documents, agreements, and transactions related to the Waiver of
                                                Liability and Indemnity Agreement, whether initiated, signed, or
                                                executed electronically. I understand and acknowledge that my electronic
                                                waiver holds the same legal validity and enforceability as my
                                                handwritten waiver. By providing this consent, I agree to conduct
                                                business and transactions electronically and accept electronic records
                                                as legally binding representations of agreements and actions. I also
                                                understand that I may withdraw this consent at any time by notifying the
                                                relevant parties in writing, but such withdrawal may affect my ability
                                                to participate in or continue with the aforementioned activity or
                                                transactions.<br/><br/>

                                                I have carefully read the Waiver of Liability and Indemnity Agreement
                                                and fully understand that I give up substantial rights by signing it and
                                                I do sign it voluntarily. I agree to participate knowing the risks and
                                                conditions involved and do so entirely of my own free will. I
                                                acknowledge reading the rules and regulations of the axe-throwing
                                                facility and agree to abide by them.<br/><br/>

                                                By entering into this agreement, I am not relying on any oral or written
                                                representations or statements made by the axe-throwing facility other
                                                than what is outlined in the Waiver of Liability and Indemnity
                                                Agreement. Do not under any circumstances sign this waiver if you do not
                                                feel you can fully, competently, emotionally, and mentally adhere to
                                                everything mentioned in the Waiver of Liability and Indemnity Agreement.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>E-Signature{this.state.type === "M" && " Parent or Guardian's "}First
                                                Name:</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="texr" className="form-control" id="waiver_esign_first_name"
                                                   name="fname"
                                                   placeholder={"Retype" + (this.state.type === "M" ? " Parent or Guardian's" : "") + " First Name Here"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>E-Signature{this.state.type === "M" && " Parent or Guardian's "}Last
                                                Name:</label>
                                        </div>
                                        <div className="col-md-8">
                                            <input type="text" className="form-control" id="waiver_esign_last_name"
                                                   placeholder={"Retype" + (this.state.type === "M" ? " Parent or Guardian's" : "") + " Last Name Here"}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mb-30 mt-1 d-flex justify-content-between">
                                    <input type="button" className="btn-grey"
                                           onClick={this.onCloseSignModal}
                                           value="Close"/>
                                    {this.state.editFlag &&
                                        <input type="button" id="btnRemove" className="btn-green bg-red"
                                               value="Remove" onClick={this.removeWaiver}/>
                                    }
                                    <input type="button" id="btnAgree" className="btn-green"
                                           value="Agree to this Document" onClick={this.addWaiver}/>
                                </div>
                            </form>
                        </div>
                    </BModal.Body>
                </BModal>

                <Modal closeOnEsc={false} closeOnOverlayClick={false} showCloseIcon={false} open={open}
                       onClose={this.onCloseModal} center>
                    <h2>&nbsp;&nbsp;&nbsp;&nbsp;Loading...&nbsp;&nbsp;&nbsp;&nbsp;</h2>
                    <p style={{textAlign: 'center'}}>
                        <img width={'40'} alt="Loading..." height={'40'} src={'/img/loading1.gif'}/>
                    </p>
                </Modal>
            </ReactPlaceholder>
        )

    }
}

export default withRouter(signwaiver);